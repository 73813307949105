import React, { useEffect, useContext, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Form, Button, Input, Select, InputNumber, Modal, Spin, message, Tooltip } from 'antd'
import { API } from 'aws-amplify';
import '../../css/requestform.css'
import ARTable from '../requestFormComponents/ARTable'
import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'
import SupportingDoc from '../requestFormComponents/SupportingDoc'
import APTable from '../requestFormComponents/APTable';
import { AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AP_REQUESTED_INFORMATION, AR_REQUESTED_INFORMATION, AWAITING_AP_APPROVAL, AWAITING_AR_RESUBMISSION, BusinessCC, descSeparator, DRAFT, enableEditForAp, formatDate, formatTimezone, groupByKey, sum, TBP, AWAITING_AP_RESUBMISSION, AP_SUBMITTED, allowAR, errorMsgSeparator, ADMIN_REQUESTED_AR_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION, groupByKeyReturnObj, validatePostingKey, getDecimalCount, cancelTimerInterval } from '../helper';
import {
    listCommXchargeDZfinApprovals,
    listCommXchargeDT007AS,
    listCommXchargeDCsks,
    listCommXchargeDCepcs,
    listCommXchargeDCoas,
    getCrossChargeForm,
    getCommXchargeDTcurc,
    listCommXchargeDJ1bbranches,
    listCommXchargeDT001S,
    listCommXchargeDS4Brbusinessplaces,
    getCommXchargeDS4Migratedcompanycode,
    listCommXchargeDS4Costcenters,
    listCommXchargeDS4Profitcenters,
    listCommXchargeDS4Taxcodes,
    listCommXchargeDS4Internalorders,
    listCommXchargeDS4Requesters
} from '../../graphql/queries';
import { apsubmitCrossChargeForm, submitCrossChargeForm } from '../../graphql/mutations';
import ContextData from '../context/ContextData';
import CommentHistory from '../reviewFormComponents/CommentHistory';
import { InfoCircleOutlined } from '@ant-design/icons';
import AddComments from '../requestFormComponents/AddComments';
import { filterAndSort, getSAPType } from '../../utils/utils';


const RequestForm = () => {
    const submitBtnRef = useRef();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const contextValue = useContext(ContextData);
    const { currencyOption, currentUser, marsTP, timerCtxt, currencyFormat } = contextValue;

    const [messageApi, contextHolder] = message.useMessage();

    let { formID } = useParams();
    const { state } = useLocation();


    // console.log("formID", formID);
    // console.log("state", state);

    const [isLoading, setIsLoading] = useState(false);
    const [spinTip, setSpinTip] = useState('');
    const [openAddComment, setOpenAddComment] = useState(false);
    const [sendingCCOption, setSendingCCOption] = useState(null);
    // state to handle form fields ( created wherever req )
    const [requestType, setRequestType] = useState('');
    const [currency, setCurrency] = useState('');
    const [currencyDec, setCurrencyDec] = useState('');
    const [sendingCC, setSendingCC] = useState('');
    const [receivingCC, setReceivingCC] = useState('');
    const [supportingDocs, setSupportingDocs] = useState([])

    const [sendingCCSAP, setSendingCCSAP] = useState("");
    const [receivingCCSAP, setReceivingCCSAP] = useState("");

    const [ARItemsFinal, setARItemsFinal] = useState([]);
    const [APItemsFinal, setAPItemsFinal] = useState([]);

    const [ARItemsUpdate, setARItemUpdate] = useState([]);
    const [APItemsUpdate, setAPItemUpdate] = useState([]);

    // states for header fields dropdown 
    const [receivingCCOption, setReceivingCCOption] = useState(null);
    const [receivingCCData, setReceivingCCData] = useState(null);
    const [apRequestorList, setAPRequestorList] = useState(null);
    // const [currencyOption, setCurrencyOption] = useState(null);

    const [sendingCodeCountryData, setSendingCodeCountryData] = useState(null);

    // states for AR dropdown options 
    const [taxCodeOption, setTaxCodeOption] = useState(null);
    const [costCenterOption, setCostCenterOption] = useState(null);
    const [profitCenterOption, setProfitCenterOption] = useState(null);
    const [orderNumOption, setOrderNumOption] = useState(null);
    const [sendingBusinessOption, setSendingBusinessOption] = useState(null);

    // states for AP dropdown options 
    const [taxCodeOptionAP, setTaxCodeOptionAP] = useState(null);
    const [costCenterOptionAP, setCostCenterOptionAP] = useState(null);
    const [profitCenterOptionAP, setProfitCenterOptionAP] = useState(null);
    const [orderNumOptionAP, setOrderNumOptionAP] = useState(null);
    const [receivingBusinessOption, setReceivingBusinessOption] = useState(null);

    const [costCenterData, setCostCenterData] = useState(null);
    const [costCenterDataAP, setCostCenterDataAP] = useState(null);

    const [country, setCountry] = useState('');

    const [formData, setFormData] = useState(null);


    // const formDisableCondition = formDisable(formData?.formStatus)
    const formDisableCondition = !(allowAR(formData?.formStatus, formData?.arRequestor, currentUser?.userID))

    const reqTypeOption = [
        {
            value: 'invoice',
            label: 'Invoice',
        },
        {
            value: 'creditNote',
            label: 'Credit Note',
        }
    ]



    const setSAPForSendingCC = async () => {
        const sap = await getSAPType(sendingCC);

        setSendingCCSAP(sap);
    }

    const setSAPForReceivingCC = async () => {
        const sap = await getSAPType(receivingCC);

        setReceivingCCSAP(sap);
    }

    // Getting Sending CC SAP type (Used for mainly in other cmps)
    useEffect(() => {
        if (sendingCC) {
            setSAPForSendingCC();
        }
    }, [sendingCC]);

    // Getting Receiving CC SAP type (Used for mainly in other cmps)
    useEffect(() => {
        if (receivingCC) {
            setSAPForReceivingCC();
        }
    }, [receivingCC]);


    // to clear all the leftout timeouts from a component
    useEffect(() => {

        const timerCancel = setInterval(() => {
            const { timerCtxt } = contextValue;
            for (let j = 0; j < timerCtxt.length; j++) {
                clearTimeout(timerCtxt[j])
            }
        }, cancelTimerInterval)

        return () => clearInterval(timerCancel)

    }, [timerCtxt]);

    useEffect(() => {
        const limit = 3;

        // func to call until we get reponse or exceed the limit 
        const callApiRecurring = (data, limitLeft) => {
            if (data !== null || limitLeft <= 0) {
                setIsLoading(false);
                if (limitLeft <= 0) {
                    Modal.error({
                        className: 'info-modal',
                        title: "There is an error while loading data. Please reload the form. If issue persists create a GIS Incident for Cross charge.",
                        okText: "Ok",
                        okButtonProps: { "className": "modal-btn" },
                        onOk: () => {
                            navigate('/');
                        }
                    });
                }
                return 1
            }
            else {
                setIsLoading(true);

                // this condition is for when copying existinf request
                let iDToGet = '';

                if (state?.copyId) {
                    iDToGet = state?.copyId;
                }
                else {
                    iDToGet = formID;
                }

                API
                    .graphql({ query: getCrossChargeForm, variables: { id: iDToGet } })
                    .then(async (response) => {
                        // console.log("form getCrossChargeForm response", response);
                        if (response?.errors) {
                            setTimeout(() => {
                                limitLeft--
                                callApiRecurring(response?.data?.getCrossChargeForm, limitLeft);
                            }, 5000);

                        } else if (!response?.errors) {



                            // setFormData(response?.data?.getCrossChargeForm);
                            form.setFieldsValue({ ...response?.data?.getCrossChargeForm, ...(!response?.data?.getCrossChargeForm?.requestDate && { requestDate: formatDate() }) });
                            setARItemUpdate(response?.data?.getCrossChargeForm?.arLineItems);
                            setAPItemUpdate(response?.data?.getCrossChargeForm?.apLineItems);
                            setCurrency(response?.data?.getCrossChargeForm?.currency);
                            setRequestType(response?.data?.getCrossChargeForm?.requestType);
                            // setSupportingDocs(response?.data?.getCrossChargeForm?.attachments ? response?.data?.getCrossChargeForm?.attachments : []);

                            response?.data?.getCrossChargeForm?.currency && getCurrencyDec(response?.data?.getCrossChargeForm?.currency);

                            if (!state?.copyId) {
                                setFormData(response?.data?.getCrossChargeForm);
                                setSupportingDocs(response?.data?.getCrossChargeForm?.attachments ? response?.data?.getCrossChargeForm?.attachments : []);
                                setIsLoading(false);
                            } else {
                                callWhileCopying(formID, limitLeft, callApiRecurring);
                            }


                            const sendingCodeCountryDataCpy = await getSendingCmpnyCode(response?.data?.getCrossChargeForm?.arRequestor.toUpperCase());

                            // needed when user opens the form later to edit to populate all pop up data based on sending and receving code
                            if ([DRAFT, AWAITING_AR_RESUBMISSION, ADMIN_REQUESTED_AR_RESUBMISSION].includes(response?.data?.getCrossChargeForm?.formStatus?.toUpperCase()) || enableEditForAp(response?.data?.getCrossChargeForm?.formStatus, response?.data?.getCrossChargeForm?.apRequestor, currentUser?.userID) || state?.copyId) {
                                const sendingCCRes = response?.data?.getCrossChargeForm?.sendingCompanyCode;
                                const receivingCCRes = response?.data?.getCrossChargeForm?.receivingCompanyCode;


                                // if sending cc is present in the form only then calling associated API's
                                if (sendingCCRes) {
                                    setSendingCC(sendingCCRes);

                                    // Can't use receivingCCSAP here bcs receivingCC state is set after this func is over
                                    const sap = await getSAPType(sendingCCRes);

                                    const data = sendingCodeCountryDataCpy?.filter((d) => {
                                        return d.bukrs === sendingCCRes;
                                    });

                                    getCostCenter(sendingCCRes, setCostCenterOption, setCostCenterData, sap);

                                    getProfitCenter(sendingCCRes, setProfitCenterOption, sap);

                                    getOrderNumber(sendingCCRes, setOrderNumOption, sap);

                                    getTaxCode(data[0]?.land1, setTaxCodeOption, sap);

                                    if (BusinessCC.includes(sendingCCRes)) {
                                        getBusinessBranch(sendingCCRes, setSendingBusinessOption, sap);
                                    }

                                }

                                // This is running when you are clicking a existing req from dashboard
                                // if receving cc is present in the form only then calling associated API's
                                if (receivingCCRes) {
                                    setReceivingCC(receivingCCRes);

                                    // can't use receivingCCSAP here bcs sendingCC state is set after this func is over
                                    const sap = await getSAPType(receivingCCRes);

                                    const receivingCCDataCpy = await getReceivingCmpnyCode();

                                    getAPRequestors(receivingCCRes, sap);


                                    const data = receivingCCDataCpy?.filter((d) => {
                                        return d.bukrs === receivingCCRes
                                    });

                                    getTaxCode(data[0]?.land1, setTaxCodeOptionAP, sap);

                                    getCostCenter(receivingCCRes, setCostCenterOptionAP, setCostCenterDataAP, sap);

                                    getProfitCenter(receivingCCRes, setProfitCenterOptionAP, sap);

                                    getOrderNumber(receivingCCRes, setOrderNumOptionAP, sap);

                                    if (BusinessCC.includes(receivingCCRes)) {
                                        getBusinessBranch(receivingCCRes, setReceivingBusinessOption, sap);
                                    }
                                }
                            }

                        }

                    }).catch((err) => {
                        console.log("get form err", err);
                        setTimeout(() => {
                            limitLeft--
                            callApiRecurring(err?.data?.getCrossChargeForm, limitLeft)
                        }, 5000);
                    })



            }
        }

        callApiRecurring(formData, limit);

    }, [formID, state?.copyId]);

    useEffect(() => {
        getReceivingCmpnyCode();
    }, []);

    //api calling to get actual req ID and other data while copying the req
    const callWhileCopying = (formID, limitLeft, callApiRecurring) => {
        form.setFieldValue('requestId', null); //to avoid any exception of duplicating requestId
        API
            .graphql({ query: getCrossChargeForm, variables: { id: formID } })
            .then(async (response) => {
                if (response?.errors) {
                    setTimeout(() => {
                        limitLeft--
                        callApiRecurring(response?.data?.getCrossChargeForm, limitLeft);
                    }, 5000);

                } else if (!response?.errors) {
                    setFormData(response?.data?.getCrossChargeForm);
                    form.setFieldValue('arRequestor', response?.data?.getCrossChargeForm?.arRequestor);
                    form.setFieldValue('requestDate', response?.data?.getCrossChargeForm?.requestDate);
                    form.setFieldValue('requestId', response?.data?.getCrossChargeForm?.requestId);
                    form.setFieldValue('commentHistoryItems', null);
                    form.setFieldValue('invoiceDate', null);
                    form.setFieldValue('postingDate', null);
                    setIsLoading(false);
                }
            }).catch((err) => {
                console.log("get form err", err);
                setTimeout(() => {
                    limitLeft--
                    callApiRecurring(err?.data?.getCrossChargeForm, limitLeft)
                }, 5000);
            })
    }


    const getSendingCmpnyCode = async (empID) => {

        setSendingCCOption(null);

        let sccData = []

        const getSendingCmpnyCodeLoop = async (nxtTkn) => {
            let variablesParams = {
                limit: 1000
            };

            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    limit: 1000
                }
            }

            const sccData2 = await API
                .graphql({ query: listCommXchargeDT001S, variables: variablesParams })
                .then(async (response) => {
                    // console.log("sending cmpny code data", response.data.listCommXchargeDT001S)

                    // console.log("SENDING FUNC listCommXchargeDT001S:", response);

                    const token = response?.data?.listCommXchargeDT001S?.nextToken;



                    const refinedOpt = response?.data?.listCommXchargeDT001S?.items?.map(d => {
                        return {
                            value: d.bukrs,
                            label: d.bukrs + descSeparator + d.butxt,
                            sap: d.dtype
                        }
                    });


                    setSendingCCOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });

                    setSendingCodeCountryData((prev) => {
                        return [...(prev ? prev : []), ...response?.data?.listCommXchargeDT001S?.items]
                    });

                    if (token) {
                        await getSendingCmpnyCodeLoop(token);
                    }

                    return response?.data?.listCommXchargeDT001S?.items;
                })
                .catch(error => {
                    console.log("error in sendin cc", error);
                });

            sccData = [...sccData, ...sccData2]
        }

        await getSendingCmpnyCodeLoop('initial');
        // console.log("sccData", sccData)

        return sccData;

    }


    const getReceivingCmpnyCode = async () => {

        setReceivingCCOption(null);

        const filter = {
            rel_grp: { notContains: "OC01" }
        }

        let recevingData = []

        const getReceivingCmpnyCodeLoop = async (nxtTkn) => {

            let variablesParams = {
                // filter: filter, 
                limit: 1000
            };

            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    // filter: filter,
                    limit: 1000 //1000
                }
            }

            const recevingData2 = await API.graphql({ query: listCommXchargeDT001S, variables: variablesParams })
                .then(async (response) => {
                    // console.log("receiving cmpny code data", response.data.listCommXchargeDT001S);
                    const token = response?.data?.listCommXchargeDT001S?.nextToken
                    const receivingCCArray = response?.data?.listCommXchargeDT001S?.items;
                    const refinedOpt = response?.data?.listCommXchargeDT001S?.items?.map(d => {
                        return {
                            value: d.bukrs,
                            label: d.bukrs + descSeparator + d.butxt,
                            sap: d.dtype
                        }
                    })

                    setReceivingCCOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });

                    setReceivingCCData((prev) => {
                        return [...(prev ? prev : []), ...receivingCCArray]
                    });

                    if (token) {
                        await getReceivingCmpnyCodeLoop(token);
                    }


                    return receivingCCArray;
                }).catch(error => {
                    console.log("error in receiving cc", error);
                });

            recevingData = [...recevingData, ...recevingData2];

        }

        await getReceivingCmpnyCodeLoop('initial');

        return recevingData;

    }



    const getTaxCode = (cntry, setOption, sap) => {
        // S4 => listCommXchargeDS4Taxcodes
        // FIT or MARS => listCommXchargeDT007AS

        setOption(null); //if not done this it will append for every call of this func


        let listQuery;
        let listQueryName;

        const isS4 = sap === "s4";


        if (isS4) {
            listQuery = listCommXchargeDS4Taxcodes;
            listQueryName = "listCommXchargeDS4Taxcodes";
        }
        else {
            listQuery = listCommXchargeDT007AS;
            listQueryName = "listCommXchargeDT007AS";
        }



        const filter = {
            land1: { eq: cntry },
            xinact: { ne: 'X' }
        };

        // Recursive func
        const getTaxCodeLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: 1000 };

            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: 1000
                }
            }

            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then((response) => {
                    const token = response?.data?.[listQueryName]?.nextToken;
                    const items = response?.data?.[listQueryName]?.items;

                    // console.log("tax code response: ", items);

                    const refinedOpt = items?.map((d) => {
                        if (d?.text1) {
                            return {
                                value: d.mwskz,
                                label: d.mwskz + descSeparator + d.text1,
                                sap: d.dtype?.toLowerCase()
                            }
                        }
                        else {
                            return {
                                value: d.mwskz,
                                label: d.mwskz
                            }
                        }

                    });

                    setOption((prev) => {
                        const uniqueVals = new Set();

                        const sorted = [...(prev ? prev : []), ...refinedOpt]
                            .filter((item) => {
                                if (uniqueVals?.has(item.value)) {
                                    return false;
                                }

                                uniqueVals.add(item.value);

                                return true;
                            });

                        // Sorting the values in Ascending order
                        sorted.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        }));


                        return sorted;
                    });

                    if (token) {
                        getTaxCodeLoop(token);
                    }
                })
                .catch(err => {
                    console.log("err getting tax code", err);
                });
        }

        getTaxCodeLoop('initial');
    }



    const getCostCenter = (cmpnyCode, setOption, setData, sap) => {
        // S4 => listCommXchargeDS4Costcenters
        // FIT or MARS => listCommXchargeDCsks


        setOption(null); //if not done this it will append for every call of this func
        setData(null);

        const isS4 = sap === "s4";

        // console.log(`sap for ${cmpnyCode} in cost centers fetching: `, isS4);

        let filter;
        let listQuery;
        let listQueryName;

        if (isS4) {
            listQuery = listCommXchargeDS4Costcenters;
            listQueryName = "listCommXchargeDS4Costcenters";

            filter = {
                companycode: { eq: cmpnyCode }
            }
        }
        else {
            listQuery = listCommXchargeDCsks;
            listQueryName = "listCommXchargeDCsks";

            filter = {
                bukrs: { eq: cmpnyCode }
            }
        }


        // Recursive func
        const getCostCenterLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: 1000 };

            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: 1000
                }
            }

            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then(response => {
                    const items = response?.data?.[listQueryName]?.items;

                    // console.log(`cost center resp (${sap}) : `, items);

                    const token = response?.data?.[listQueryName]?.nextToken;

                    const refinedOpt = items?.map((d) => {
                        if (isS4) {
                            if (d.costcenter) {
                                return {
                                    value: d.costcenter,
                                    label: d.costcenter + descSeparator + d.costcenterdescription
                                }
                            }
                            else {
                                return {
                                    value: d.costcenter,
                                    label: d.costcenter
                                }
                            }
                        }
                        else {
                            if (d.ltext) {
                                return {
                                    value: d.kostl,
                                    label: d.kostl + descSeparator + d.ltext
                                }
                            }
                            else {
                                return {
                                    value: d.kostl,
                                    label: d.kostl
                                }
                            }
                        }
                    });

                    // This is for the Select Options
                    setOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });


                    // This stores the entire data of response
                    setData((prev) => {
                        return [...(prev ? prev : []), ...items];
                    });

                    if (token) {
                        getCostCenterLoop(token);
                    }
                }).catch(err => {
                    console.log(`cost center error (${sap}) : `, err);
                });
        }

        getCostCenterLoop('initial');
    }


    const getProfitCenter = (cmpnyCode, setOption, sap) => {
        // S4 => listCommXchargeDS4Profitcenters
        // FIT or MARS => listCommXchargeDCepcs

        setOption(null);


        const isS4 = sap === "s4";

        let filter;
        let listQuery;
        let listQueryName;

        if (isS4) {
            listQuery = listCommXchargeDS4Profitcenters;
            listQueryName = "listCommXchargeDS4Profitcenters";

            filter = {
                companycode: { eq: cmpnyCode }
            }
        }
        else {
            listQuery = listCommXchargeDCepcs;
            listQueryName = "listCommXchargeDCepcs";

            filter = {
                bukrs: { eq: cmpnyCode }
            }
        }


        // Recursive Func
        const getProfitCenterLoop = (nxtTkn) => {
            let variablesParams = {
                limit: 500
            };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    limit: 500 //1000
                }
            }

            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then((response) => {
                    const token = response?.data?.[listQueryName]?.nextToken;

                    const items = response?.data?.[listQueryName]?.items;

                    // console.log(`profit center response of ${listQueryName}: `, items);

                    const refinedOpt = items?.map((d) => {
                        if (isS4) {
                            if (d?.profitcenterlongname) {
                                return {
                                    value: d.profitcenter,
                                    label: d.profitcenter + descSeparator + d.profitcenterlongname
                                }
                            }
                            else {
                                return {
                                    value: d.profitcenter,
                                    label: d.profitcenter
                                }
                            }
                        }
                        else {
                            if (d?.ltext) {
                                return {
                                    value: d.prctr,
                                    label: d.prctr + descSeparator + d.ltext
                                }
                            }
                            else {
                                return {
                                    value: d.prctr,
                                    label: d.prctr
                                }
                            }
                        }
                    });


                    setOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });

                    if (token) {
                        getProfitCenterLoop(token);
                    }

                })
                .catch(err => {
                    console.log(`profit center ERROR of ${sap}: `, err);
                });
        }

        getProfitCenterLoop('initial');
    }


    const getOrderNumber = (cmpnyCode, setOption, sap) => {
        // S4 => listCommXchargeDS4Internalorders
        // FIT or MARS => listCommXchargeDCoas

        setOption(null);



        const isS4 = sap === "s4";


        let listQuery;
        let listQueryName;

        if (isS4) {
            listQuery = listCommXchargeDS4Internalorders;
            listQueryName = "listCommXchargeDS4Internalorders";
        }
        else {
            listQuery = listCommXchargeDCoas;
            listQueryName = "listCommXchargeDCoas";
        }


        const filter = {
            bukrs: { eq: cmpnyCode }
        };


        // Recursive Func
        const getOrderNumberLoop = (nxtTkn) => {
            let variablesParams = {
                filter: filter,
                limit: 10000
            };

            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: 10000
                }
            }

            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then((response) => {
                    const items = response?.data?.[listQueryName]?.items;
                    // console.log(`order num response of ${sap} : `, items);

                    const token = response?.data?.[listQueryName]?.nextToken;

                    const refinedOpt = items?.map((d) => {
                        if (d.ktext) {
                            return {
                                value: d.aufnr,
                                label: d.aufnr + descSeparator + d.ktext
                            }
                        } else {
                            return {
                                value: d.aufnr,
                                label: d.aufnr
                            }
                        }
                    });


                    setOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });

                    if (token) {
                        getOrderNumberLoop(token);
                    }

                }).catch(err => {
                    console.log(`err getting order num of ${sap}`, err)
                })

        }
        getOrderNumberLoop('initial');
    }



    const getCurrencyDec = (curr) => {
        API
            .graphql({ query: getCommXchargeDTcurc, variables: { id: curr } })
            .then((response) => {
                const cd = response?.data?.getCommXchargeDTcurc?.currdec
                // console.log("currdec", cd)
                setCurrencyDec(cd);

            })
            .catch((err) => {
                console.log("err getting currdec", err);
                messageApi.open({
                    type: 'error',
                    content: 'There is an error while getting currency decimal',
                    className: 'message-style',
                    duration: 5
                });
            });
    }

    const getBusinessBranch = (cmpnyCode, setOption, sap) => {
        // S4 -> listCommXchargeDS4Brbusinessplaces
        // FIT and MARS -> listCommXchargeDJ1bbranches

        setOption(null);




        const isS4 = sap?.toLowerCase() === "s4";

        let filter = null;
        let listQuery;
        let listQueryName;

        if (isS4) {
            listQuery = listCommXchargeDS4Brbusinessplaces;
            listQueryName = "listCommXchargeDS4Brbusinessplaces";
            filter = {
                companycode: { eq: cmpnyCode }
            }
        }
        else {
            listQuery = listCommXchargeDJ1bbranches;
            listQueryName = "listCommXchargeDJ1bbranches";

            filter = {
                bukrs: { eq: cmpnyCode }
            }
        }



        // Nested Func
        const getBusinessBranchLoop = (nxtTkn) => {
            const variablesParams = {
                filter: filter,
                limit: 500
            };


            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: 500 //64
                }
            }


            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then(response => {
                    const items = response?.data?.[listQueryName]?.items;

                    // console.log(`business branch response ${sap}: `, items);

                    const token = response?.data?.[listQueryName]?.nextToken;

                    const refinedOpt = items?.map((d) => {
                        if (isS4) {
                            if (d?.businessplacename) {
                                return {
                                    value: d.branch,
                                    label: d.branch + descSeparator + d?.businessplacename
                                }
                            }
                            else {
                                return {
                                    value: d.branch,
                                    label: d.branch
                                }
                            }
                        }
                        else {
                            if (d.name) {
                                return {
                                    value: d.branch,
                                    label: d.branch + descSeparator + d.name
                                }
                            }
                            else {
                                return {
                                    value: d.branch,
                                    label: d.branch
                                }
                            }
                        }
                    });


                    setOption((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });

                    if (token) {
                        getBusinessBranchLoop(token);
                    }

                })
                .catch(err => {
                    console.log("err getting business brnch S4", err);
                });
        }


        // Recursion Call
        getBusinessBranchLoop('initial');
    }



    const handleRequestType = (val) => {
        // console.log("check req typ val", val);
        if (APItemsFinal?.length > 0 || ARItemsFinal?.length > 0) {
            setAPItemUpdate([]);
            setARItemUpdate([]);
        }
        setRequestType(val);
    }

    const handleCurrency = (val) => {
        setCurrency(val);
        getCurrencyDec(val);
    }

    const handleSendingCC = (val, option) => {
        // manually getting SAP type here becasue state is not updating before this func ends
        const sap = option?.sap?.toString()?.toLowerCase();

        if (APItemsFinal?.length > 0 || ARItemsFinal?.length > 0) {
            setAPItemUpdate([]);
            setARItemUpdate([]);
        }

        setSendingCC(val);

        const data = sendingCodeCountryData?.filter((d) => {
            return d.bukrs === val;
        });

        // console.log("geting cmpny code & cntry data", data);
        setCountry(data[0]?.land1);

        getTaxCode(data[0]?.land1, setTaxCodeOption, sap);
        getCostCenter(val, setCostCenterOption, setCostCenterData, sap);
        getProfitCenter(val, setProfitCenterOption, sap);
        getOrderNumber(val, setOrderNumOption, sap);

        form.setFieldValue('sendBusinessPlace', null);

        // Only when you are manually selecting a CC
        if (BusinessCC.includes(val)) {
            getBusinessBranch(val, setSendingBusinessOption, sap);
        }
    }

    // To get AP Requestor values and set it to a state
    const getAPRequestors = (receivingCC, sap) => {
        setAPRequestorList(null);

        const isS4 = sap?.toLowerCase() === "s4";


        let listQuery;
        let listQueryName;
        let filter;
        let idName;

        if (isS4) {
            listQuery = listCommXchargeDS4Requesters;
            listQueryName = "listCommXchargeDS4Requesters";

            filter = {
                id: { eq: receivingCC }
            }

            idName = { id: "id", name: "fullName" }
        }
        else {
            listQuery = listCommXchargeDZfinApprovals;
            listQueryName = "listCommXchargeDZfinApprovals";

            filter = {
                rel_grp: { notContains: "OC01" },
                company_code: { eq: receivingCC }
            }

            idName = { id: "emp_id", name: "emp_name" }
        }




        const getAPRequestorsLoop = (nxtTkn) => {
            let variablesParams = {
                limit: 1000
            }

            if (!isS4) {
                variablesParams = { ...variablesParams, filter: filter }
            }


            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    limit: 1000
                }

                if (!isS4) {
                    variablesParams = { ...variablesParams, filter: filter }
                }
            }


            API
                .graphql({ query: listQuery, variables: variablesParams })
                .then((response) => {
                    const items = response?.data?.[listQueryName]?.items;


                    // console.log(`ap requestors response of ${sap}`, items);

                    const token = response?.data?.[listQueryName]?.nextToken;

                    const refinedOpt = items?.map((d) => {
                        if (d[idName.id]) {
                            return {
                                value: d[idName.id]?.toUpperCase(),
                                label: d[idName.name] + descSeparator + d[idName.id]?.toUpperCase()
                            };
                        }
                    });


                    setAPRequestorList((prev) => {
                        const result = filterAndSort(prev, refinedOpt);

                        return result;
                    });



                    if (token) {
                        getAPRequestorsLoop(token);
                    }
                })
                .catch((err) => {
                    console.log(`AP Requestors ERROR`, err);
                });
        }

        getAPRequestorsLoop("initial");
    }



    const onSelectReceivingCC = (value, option) => {
        // manually getting SAP type here becasue state is not updating before this func ends
        const sap = option?.sap?.toString()?.toLowerCase();


        getAPRequestors(value, sap);

        // WHY IS THIS CHECKED AND ASSIGNED??
        // AP-ARItemsFinal are the items from AR and AP items section
        if (APItemsFinal?.length > 0 || ARItemsFinal?.length > 0) {
            setAPItemUpdate([]);
            setARItemUpdate([]);
        }

        setReceivingCC(value);


        form.setFieldValue('apRequestor', null);
        form.setFieldValue('recvBusinessPlace', null);

        const data = receivingCCData?.filter((d) => {
            return d.bukrs === value;
        });

        getTaxCode(data[0]?.land1, setTaxCodeOptionAP, sap);
        getCostCenter(value, setCostCenterOptionAP, setCostCenterDataAP, sap);
        getProfitCenter(value, setProfitCenterOptionAP, sap);
        getOrderNumber(value, setOrderNumOptionAP, sap);

        // Only when you are manually selecting a CC
        if (BusinessCC.includes(value)) {
            getBusinessBranch(value, setReceivingBusinessOption, sap);
        }
    };

    const getARItems = (arItems) => {
        setARItemsFinal(arItems)
    }

    const getAPItems = (apItems) => {
        setAPItemsFinal(apItems)
    }

    // validates credit and debit amount 
    const validateAmount = (items) => {

        const arAmountObj = groupByKey(items, "postingKey")
        // console.log("check", arAmountObj, Object.keys(arAmountObj).includes('01'))

        const debitAmnt = sum(arAmountObj['01']) + sum(arAmountObj['40']) + sum(arAmountObj['21'])
        const creditAmnt = sum(arAmountObj['11']) + sum(arAmountObj['50']) + sum(arAmountObj['31'])

        const mainAmt = (arAmountObj['01'] && arAmountObj['01'][0]) ||
            (arAmountObj['21'] && arAmountObj['21'][0]) ||
            (arAmountObj['11'] && arAmountObj['11'][0]) ||
            (arAmountObj['31'] && arAmountObj['31'][0])
        const deciCount = getDecimalCount(mainAmt);

        // console.log("debitAmnt", debitAmnt?.toFixed(deciCount));
        // console.log("creditAmnt", creditAmnt?.toFixed(deciCount));

        if (debitAmnt?.toFixed(deciCount) === creditAmnt?.toFixed(deciCount)) {
            return true
        } else {
            return false
        }


    }

    // validates first line validation of AR or AP items 
    const firstLineAmntValidation = (arItems, apItems) => {
        const arAmountObj = groupByKey(arItems, "postingKey")
        const apAmountObj = groupByKey(apItems, "postingKey")
        if (requestType === 'invoice') {
            if (arAmountObj['01'] && apAmountObj['31']) {
                return arAmountObj['01'][0] === apAmountObj['31'][0]
            }

        } else if (requestType === 'creditNote') {
            if (arAmountObj['11'] && apAmountObj['21']) {
                return arAmountObj['11'][0] === apAmountObj['21'][0]
            }

        }

        return true

    }

    // validates if TP exists or not 
    const TradingPartnerNullValidation = (items, itemType) => {

        const itemObj = groupByKeyReturnObj(items, "postingKey")

        let obj = null
        if (itemType === 'ar') {
            obj = itemObj['01'] || itemObj['11']
        } else {
            obj = itemObj['21'] || itemObj['31']
        }


        let tp = obj ? obj[0]?.tradingPartner : '';
        tp = tp && tp.trim();

        // checks whether trading partner is empty or not 
        const IsTpNull = (type) => {
            if (tp === '' || tp === ' ' || tp === null || tp === undefined) {

                return false
            } else {
                return true
            }
        }

        return IsTpNull()

    }

    // validates if trading partner is matching to cmpny code 
    const TradingPartnerMatchingValidation = (items, itemType) => {

        const itemObj = groupByKeyReturnObj(items, "postingKey")

        let obj = null
        if (itemType === 'ar') {
            obj = itemObj['01'] || itemObj['11']
        } else {
            obj = itemObj['21'] || itemObj['31']
        }


        let tp = obj ? obj[0]?.tradingPartner : '';
        tp = tp && tp.trim();

        // checks whether tp matches sending / receving cc 
        const IsTpMatchCC = (headerCC, CC, userType, cmpType) => {
            if (headerCC !== CC) {
                return false
            } else {
                return true
            }
        }

        // checks whether tp matches sending / receving cc according to AR and AP
        const CheckTP_CC = () => {
            if (tp) {
                const isTP = tp.substring(0, 2);
                if (isTP === 'TP') {
                    const cmpnyCd = tp.replace('TP', '');
                    // console.log("cmpnyCd", cmpnyCd);
                    if (itemType === 'ar') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(receivingCC)) {
                            return IsTpMatchCC(marsTP[receivingCC], cmpnyCd, 'Customer', 'Receiving');
                        } else {
                            return IsTpMatchCC(receivingCC, cmpnyCd, 'Customer', 'Receiving');
                        }

                    } else if (itemType === 'ap') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(sendingCC)) {
                            return IsTpMatchCC(marsTP[sendingCC], cmpnyCd, 'Vendor', 'Sending');
                        } else {
                            return IsTpMatchCC(sendingCC, cmpnyCd, 'Vendor', 'Sending');
                        }

                    }

                } else {
                    const newTP = String(parseInt(tp));
                    if (itemType === 'ar') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(receivingCC)) {
                            return IsTpMatchCC(marsTP[receivingCC], newTP, 'Customer', 'Receiving');
                        } else {
                            return IsTpMatchCC(receivingCC, newTP, 'Customer', 'Receiving');
                        }

                    } else if (itemType === 'ap') {
                        //this check if the company code belongs to specific cmpny code in the static table
                        if (Object.keys(marsTP).includes(sendingCC)) {
                            return IsTpMatchCC(marsTP[sendingCC], newTP, 'Vendor', 'Sending');
                        } else {
                            return IsTpMatchCC(sendingCC, newTP, 'Vendor', 'Sending');
                        }

                    }
                }
            } else {
                return true
            }
        }

        return CheckTP_CC()

    }

    //validates profit center is mandsatory for 40 & 50 while submitting
    const ValidatePrctr = (items) => {
        const itemObj = groupByKeyReturnObj(items, "postingKey");

        const mergedList = [
            ...(itemObj['40'] ? itemObj['40'] : []),
            ...(itemObj['50'] ? itemObj['50'] : [])
        ];

        for (let i = 0; i < mergedList?.length; i++) {
            if (!mergedList[i]?.prctr) {
                return false
            }
        }
        return true
    }


    // main validation function 
    const validate = () => {

        const errors = {}

        if (ARItemsFinal?.length <= 0) {
            errors.arLineItems = 'Please add AR line items'
        }

        if (ARItemsFinal?.length > 0) {
            if (!validateAmount(ARItemsFinal)) {
                errors.aramount = 'AR debit and credit amounts are not matching'
            }
        }

        if (APItemsFinal?.length > 0) {
            if (!validateAmount(APItemsFinal)) {
                errors.apamount = 'AP debit and credit amounts are not matching'
            }
        }

        if (ARItemsFinal?.length > 0 && APItemsFinal?.length > 0) {
            if (!firstLineAmntValidation(ARItemsFinal, APItemsFinal)) {
                errors.firstLineMismatch = 'Customer line item amount is not equal to Vendor line item amount'
            }
        }

        if (sendingCC === receivingCC) {
            errors.sameCC = 'Sending company code and Receiving company code cannot be same'
        }

        if (supportingDocs?.length <= 0) {
            errors.document = "Please upload atleast one attachment"
        }

        if (enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)) {
            if (APItemsFinal?.length <= 0) {
                errors.apLineItems = 'Please add AP line items'
            }
        }

        if (!(sendingCCOption?.map(cc => cc.value).includes(sendingCC))) {
            errors.send = 'The selected sending company code does not belong to you'
        }

        if (!(receivingCCOption?.map(cc => cc.value).includes(receivingCC))) {
            errors.receive = 'The selected receiving company code does not exist'
        }

        if (!(apRequestorList?.map(cc => cc.value).includes(form.getFieldValue('apRequestor')))) {
            errors.apreq = 'The selected AP requestor does not exist'
        }

        if (!(currencyOption?.map(cc => cc.value).includes(currency))) {
            errors.cur = 'The selected currency does not exist'
        }

        if (ARItemsFinal?.length > 0) {
            if (!TradingPartnerMatchingValidation(ARItemsFinal, 'ar')) {
                errors.arTPMismatch = 'IC Customer does not belong to Receiving Company Code'
            }

            if (!TradingPartnerNullValidation(ARItemsFinal, 'ar')) {
                errors.arTPNull = 'Customer is not an IC Customer. Trading Partner is Missing'
            }

            if (!validatePostingKey(ARItemsFinal, requestType, 'ar')) {
                errors.arPstnKey = 'AR items doesnt contain correct posting key according to request type'
            }

            if (!ValidatePrctr(ARItemsFinal)) {
                errors.prctrErrAR = 'Profit center in AR items is mandatory for posting key 40 & 50'
            }
        }

        if (APItemsFinal?.length > 0) {
            if (!TradingPartnerMatchingValidation(APItemsFinal, 'ap')) {
                errors.apTPMismatch = 'IC Vendor does not belong to Sending Company Code'
            }

            if (!TradingPartnerNullValidation(APItemsFinal, 'ap')) {
                errors.apTPNull = 'Vendor is not an IC Vendor. Trading Partner is Missing'
            }

            if (!validatePostingKey(APItemsFinal, requestType, 'ap')) {
                errors.apPstnKey = 'AP items doesnt contain correct posting key according to request type'
            }

            if (!ValidatePrctr(APItemsFinal)) {
                errors.prctrErrAP = 'Profit center in AP items is mandatory for posting key 40 & 50'
            }
        }


        console.log("errors", errors);
        // setFormErrors(errors)

        return (Object.keys(errors).length === 0) ? [true, errors] : [false, errors];
    }

    // polling SAP validation 
    const checkSapErrorMsgReccuring = () => {

        API
            .graphql({ query: getCrossChargeForm, variables: { id: formID } })
            .then((response) => {
                // console.log("sap validation res", response);
                const arResult = response?.data?.getCrossChargeForm?.arresult?.toUpperCase();
                const apResult = response?.data?.getCrossChargeForm?.apresult?.toUpperCase();

                const formStat = response?.data?.getCrossChargeForm?.formStatus?.toUpperCase();

                const arSapErrorMessage = response?.data?.getCrossChargeForm?.arSAPErrorMessage
                const apSapErrorMessage = response?.data?.getCrossChargeForm?.apapSAPErrorMessage



                if (arResult === 'ERROR' || apResult === 'ERROR') {
                    setIsLoading(false);
                    setSpinTip('');
                    Modal.error({
                        className: 'info-modal',
                        title: (
                            <>
                                <h3>Validation Error:</h3>
                                <ul>
                                    {arSapErrorMessage && arSapErrorMessage?.split(errorMsgSeparator)?.map(itm => itm?.trim() && <li>{itm}</li>)}
                                    {apSapErrorMessage && apSapErrorMessage?.split(errorMsgSeparator)?.map(itm => itm?.trim() && <li>{itm}</li>)}
                                </ul>
                            </>
                        ),
                        okButtonProps: { "className": "modal-btn" }
                    })

                } else if (arResult === 'SUCCESS') {
                    if ([AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, AWAITING_AP_APPROVAL, AP_SUBMITTED, ADMIN_REQUESTED_AP_RESUBMISSION].includes(formStat)) {
                        if (apResult === 'SUCCESS') {
                            setIsLoading(false);
                            setSpinTip('');
                            Modal.success({
                                content: 'Request has been validated and sent for approval',
                                onOk: () => {
                                    navigate('/')
                                },
                                okButtonProps: { "className": "ortho-btn" }
                            });
                        } else {
                            setTimeout(() => {
                                checkSapErrorMsgReccuring()
                            }, 1000)
                        }
                    } else {
                        setIsLoading(false);
                        setSpinTip('');
                        Modal.success({
                            content: 'Request has been validated and sent for approval',
                            onOk: () => {
                                navigate('/')
                            },
                            okButtonProps: { "className": "ortho-btn" }
                        });
                    }

                } else if (!arResult || !apResult) {
                    setTimeout(() => {

                        checkSapErrorMsgReccuring()
                    }, 1000)
                }


            }).catch((err) => {
                console.log("sap validation api err", err);
                setIsLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'There is an error while validating the form. Please try again.',
                    className: 'message-style',
                    duration: 5
                });
            })

    }



    const handleSubmit = (values) => {
        // console.log("form values", values)

        let actn = '';
        let status = '';
        let queryType = '';

        if (enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)) {
            actn = 'AP_Submit';
            status = 'AP_SUBMITTED';
            queryType = apsubmitCrossChargeForm;
        } else {
            actn = 'Submit';
            status = 'Submitted';
            queryType = submitCrossChargeForm;
        }

        const payload = {
            ...formData,
            actionType: actn,
            executionID: formID,
            formStatus: status,
            // SAPFailureMessage: '',
            id: formID,
            ...values,
            arLineItems: ARItemsFinal,
            apLineItems: APItemsFinal,
            attachments: supportingDocs,
            isadminAction: "N",
            ...(enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID) ?
                { apRequestSubmittedDate: formatTimezone() }
                :
                { arRequestSubmittedDate: formatTimezone() }
            )
        }
        // console.log('submit payload', payload);


        const [validationPass, errors] = validate();

        if (validationPass) {
            // navigate('/', { state: { name: 'Xyz' } })


            setIsLoading(true);
            setSpinTip('SAP Validation is in Progess');
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            API
                .graphql({ query: queryType, variables: { input: payload } })
                .then((response) => {
                    // console.log("submit response", response);

                    checkSapErrorMsgReccuring();
                }).catch((err) => {
                    console.log("submit err", err);
                    setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'There is an error while submitting the request. Please try again.',
                        className: 'message-style',
                        duration: 5
                    });
                })

        } else {
            console.log("Failed validation please check amnt", errors);
            Modal.error({
                className: 'info-modal',
                title: (
                    <>
                        <h3>Validation Error:</h3>
                        <ul>
                            {
                                Object.values(errors)?.map(e => {
                                    return <li key={e}>{e}</li>
                                })
                            }
                        </ul>


                    </>
                ),
                okButtonProps: { "className": "modal-btn" }

            })
        }


    }



    const handleSave = () => {

        const headerFormValues = form.getFieldsValue();
        // console.log('headerFormValues', headerFormValues)

        let actn = '';
        let status = '';
        let queryType = '';

        if (enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)) {
            actn = 'AP_Save';
            status = 'AP_DRAFT';
            queryType = apsubmitCrossChargeForm;
        } else {
            actn = 'Save';
            status = DRAFT;
            queryType = submitCrossChargeForm;
        }

        const payload = {
            ...formData,
            actionType: actn,
            executionID: formID,
            formStatus: status,
            // SAPFailureMessage: '',
            id: formID,
            ...headerFormValues,
            arLineItems: ARItemsFinal,
            apLineItems: APItemsFinal,
            attachments: supportingDocs,
            isadminAction: "N"
        }
        // console.log('payload', payload);

        setIsLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        API
            .graphql({ query: queryType, variables: { input: payload } })
            .then((response) => {
                // console.log("save response", response);
                setIsLoading(false);
                messageApi.open({
                    type: 'success',
                    content: 'Request has been saved successfully',
                    duration: 5
                });
                navigate('/');
            }).catch((err) => {
                console.log("save err", err);
                setIsLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'There is an error while saving the request. Please try again.',
                    className: 'message-style',
                    duration: 5
                });
            })
    }

    const handleCancel = () => {

        const headerFormValues = form.getFieldsValue();
        console.log('headerFormValues', headerFormValues)

        let actn = '';
        let status = '';
        let queryType = '';

        if (enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)) {
            actn = 'AP_Cancel';
            status = 'AP_CANCELLED';
            queryType = apsubmitCrossChargeForm;
        } else {
            actn = 'Cancel';
            status = 'CANCELLED';
            queryType = submitCrossChargeForm;
        }

        const payload = {
            ...formData,
            actionType: actn,
            executionID: formID,
            formStatus: status,
            // SAPFailureMessage: '',
            id: formID,
            ...headerFormValues,
            arLineItems: ARItemsFinal,
            apLineItems: APItemsFinal,
            attachments: supportingDocs
        }
        // console.log('payload', payload);

        setIsLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        API
            .graphql({ query: queryType, variables: { input: payload } })
            .then((response) => {
                // console.log("cancel response", response);
                setIsLoading(false);
                messageApi.open({
                    type: 'success',
                    content: 'Request has been cancelled successfully',
                    duration: 5
                });
                navigate('/');
            }).catch((err) => {
                console.log("save err", err);
                setIsLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'There is an error while cancelling the request. Please try again.',
                    className: 'message-style',
                    duration: 5
                });
            })
    }

    const handleAddComment = (cmnts) => {
        setFormData({
            ...formData,
            commentHistoryItems: [
                {
                    "mailId": currentUser?.userEmail,
                    "time": formatTimezone(),
                    "comment": cmnts
                },
                ...(formData?.commentHistoryItems ? formData?.commentHistoryItems : [])
            ]
        })
    }


    // console.log("arItemsFinal", ARItemsFinal);
    // console.log("apItemsFinal", APItemsFinal);
    // console.log("formData.formStatus", formData);


    //   dom 
    return (
        <div className='request-form'>
            {contextHolder}
            {/* <Header /> */}
            <Spin spinning={isLoading} tip={spinTip} size='large'>
                <div className='cc-orderform-cnt'>

                    <Form
                        //   onValuesChange={onFormLayoutChange}
                        // disabled={formDisableCondition}
                        size={'small'}
                        onFinish={handleSubmit}
                        form={form}
                        autoComplete="off"
                        scrollToFirstError={true}
                    >


                        <Heading
                            heading='Request Information'
                        />
                        <HorizontalLine />

                        <table style={{ width: "100%" }}>
                            <tr className={'cc-request-fields-cnt'}>
                                <td className={`cc-request-fields-left ${(BusinessCC.includes(sendingCC) || BusinessCC.includes(receivingCC)) && 'cc-request-fields-left-with-business'}`} >
                                    <Form.Item
                                        label="Request Date"
                                        name="requestDate"
                                        className='cc-split-input-field'
                                        value='verb'
                                    >
                                        <Input className='cc-input-field' disabled={true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Request Type"
                                        name="requestType"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select request type',
                                            },
                                        ]}
                                    >
                                        <Select
                                            className='cc-input-field'
                                            // onChange={handleChange}
                                            placeholder="Select a request type"
                                            options={reqTypeOption}
                                            value={requestType}
                                            onChange={handleRequestType}
                                            disabled={formDisableCondition}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Request Description"
                                        name="requestDescription"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Request description is mandatory',
                                            },
                                        ]}
                                    >
                                        {/* <TextArea rows={1} className='cc-textarea' showCount maxLength={25} /> */}
                                        <Input placeholder='Enter description' className='cc-input-field' maxLength={25} showCount disabled={formDisableCondition} />
                                    </Form.Item>


                                    <div className='cc-business-place-cnt'>

                                        <Form.Item
                                            label="Sending Company Code"
                                            name="sendingCompanyCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select sending company code',
                                                },
                                            ]}
                                        >

                                            <Select
                                                showSearch
                                                className='cc-input-field'
                                                placeholder="Select a sending company code"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                value={sendingCC}
                                                onChange={handleSendingCC}
                                                optionLabelProp='label'
                                                // labelInValue = {true}
                                                options={sendingCCOption}
                                                disabled={formDisableCondition}
                                            />
                                        </Form.Item>

                                        {/* Business Place Field (Sending CC) */}
                                        {
                                            BusinessCC.includes(sendingCC) &&
                                            <Form.Item
                                                label="Business Place"
                                                name="sendBusinessPlace"
                                                className='cc-business-label'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select business place',
                                                    },
                                                ]}
                                            >

                                                <Select
                                                    showSearch
                                                    className='cc-input-business-field'
                                                    placeholder="Select a business place"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    // value={sendingCC}
                                                    // onChange={handleSendingCC}
                                                    optionLabelProp='label'
                                                    options={sendingBusinessOption}
                                                    disabled={formDisableCondition}
                                                />
                                            </Form.Item>

                                        }


                                    </div>


                                    <div className='cc-business-place-cnt'>
                                        <Form.Item
                                            label="Receiving Company Code"
                                            name="receivingCompanyCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select receiving company code',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                className='cc-input-field'
                                                placeholder="Select a receiving company code"
                                                optionFilterProp="children"
                                                onChange={onSelectReceivingCC}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                optionLabelProp='label'
                                                // options={[{value: '3006', label: '3006 - OCD Spain'}]}
                                                options={receivingCCOption}
                                                disabled={formDisableCondition}
                                            />
                                        </Form.Item>

                                        {/* Business Place Field (Receiving CC) */}
                                        {
                                            BusinessCC.includes(receivingCC) &&
                                            <Form.Item
                                                label="Business Place"
                                                name="recvBusinessPlace"
                                                className='cc-business-label'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select business place',
                                                    },
                                                ]}
                                            >

                                                <Select
                                                    showSearch
                                                    className='cc-input-business-field'
                                                    placeholder="Select a business place"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    // value={sendingCC}
                                                    // onChange={handleSendingCC}
                                                    optionLabelProp='label'
                                                    options={receivingBusinessOption}
                                                    disabled={formDisableCondition}
                                                />
                                            </Form.Item>

                                        }

                                    </div>

                                    <Form.Item
                                        label="Currency"
                                        name="currency"
                                        className='cc-split-input-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select currency',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            className='cc-input-field'
                                            placeholder="Select currency"
                                            optionFilterProp="children"
                                            value={currency}
                                            onChange={handleCurrency}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            optionLabelProp='value'
                                            options={currencyOption}
                                            disabled={formDisableCondition}
                                        />

                                    </Form.Item>

                                </td>

                                <td>



                                    <Form.Item
                                        label="Request ID"
                                        name="requestId"

                                    >
                                        <Input disabled={true} className='cc-input-field' />
                                    </Form.Item>

                                    <Form.Item
                                        label=<div>
                                            Invoice Date
                                            <Tooltip title="Invoice date will be set to final approval date" className='mobile-tooltip'>

                                                <InfoCircleOutlined
                                                    className='cc-icons'
                                                    style={{ marginLeft: "10px" }}
                                                />
                                            </Tooltip>
                                        </div>
                                        name="invoiceDate"

                                    >
                                        <>
                                            <Input disabled={true} placeholder={TBP} className='cc-input-field' />
                                            <Tooltip title="Invoice date will be set to final approval date" className='dsktp-tooltip'>

                                                <InfoCircleOutlined
                                                    className='cc-icons'
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Tooltip>
                                            {/* <small style={{ color: "#774def" }}>*Invoice date will be set to final approval date</small> */}
                                        </>
                                    </Form.Item>

                                    <Form.Item
                                        label=<div>
                                            Posting Date
                                            <Tooltip title="Posting date will be set to final approval date" className='mobile-tooltip'>

                                                <InfoCircleOutlined
                                                    className='cc-icons'
                                                    style={{ marginLeft: "10px" }}
                                                />
                                            </Tooltip>
                                        </div>

                                        name="postingDate"
                                    // extra="*Invoice and posting dates would be automatically set to final approval date"
                                    >
                                        <>
                                            <Input disabled={true} placeholder={TBP} className='cc-input-field' />
                                            <Tooltip title="Posting date will be set to final approval date" className='dsktp-tooltip'>
                                                <InfoCircleOutlined
                                                    className='cc-icons'
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Tooltip>
                                            {/* <small style={{ color: "#774def" }}>*Posting date will be set to final approval date</small> */}
                                        </>
                                    </Form.Item>

                                    <Form.Item
                                        label="AR Requestor"
                                        name="arRequestor"

                                    >
                                        <Input
                                            disabled={true}
                                            className='cc-input-field'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="AP Requestor"
                                        name="apRequestor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select AP requestor',
                                            },
                                        ]}

                                    >
                                        <Select
                                            showSearch
                                            // disabled={!(apRequestorList || apRequestorList?.length > 0)}
                                            className='cc-input-field'
                                            placeholder="Select a AP requestor"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            dropdownStyle={{ width: "fit-content" }}
                                            optionLabelProp='label'
                                            options={apRequestorList}
                                            disabled={formDisableCondition || apRequestorList === null}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Exchange Rate"
                                        name="exchangeRate"
                                        rules={[
                                            {
                                                validator: async (_, value) => {
                                                    if (value && getDecimalCount(value) > 5) {
                                                        throw new Error('Should not be more than 5 decimal places')
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            type='number'
                                            className='cc-input-field'
                                            placeholder='Enter exchange rate'
                                            onWheel={(e) => e.target.blur()}
                                            disabled={formDisableCondition}
                                            pattern='d\+\.\d\d$'
                                        />
                                    </Form.Item>

                                </td>
                            </tr>
                        </table>






                        <Form.Item style={{ display: 'none' }}>
                            <Button ref={submitBtnRef} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>

                    <ARTable
                        requestType={requestType}
                        currency={currency}
                        currencyDec={currencyDec}
                        getARItems={getARItems}
                        companyCode={sendingCC}
                        receivingCC={receivingCC}
                        ARItemsUpdate={ARItemsUpdate}
                        formStatus={formData?.formStatus}
                        formData={formData}
                        currentUser={currentUser?.userID}
                        // country = {country}
                        fieldOptions={[taxCodeOption, costCenterOption, costCenterData, profitCenterOption, orderNumOption]}
                        currencyFormat={currencyFormat}
                        sap={sendingCCSAP}
                    />

                    <APTable
                        requestType={requestType}
                        currency={currency}
                        currencyDec={currencyDec}
                        getAPItems={getAPItems}
                        companyCode={receivingCC}
                        sendingCC={sendingCC}
                        APItemsUpdate={APItemsUpdate}
                        formStatus={formData?.formStatus}
                        apRequestor={formData?.apRequestor}
                        currentUser={currentUser?.userID}
                        formData={formData}
                        fieldOptions={[taxCodeOptionAP, costCenterOptionAP, costCenterDataAP, profitCenterOptionAP, orderNumOptionAP]}
                        currencyFormat={currencyFormat}
                        sap={receivingCCSAP}
                    />

                    <div className='doc-comment-cnt'>
                        <SupportingDoc
                            supportingDocs={supportingDocs}
                            setSupportingDocs={setSupportingDocs}
                            formID={formID}
                            formStatus={formData?.formStatus}
                            formData={formData}
                            apRequestor={formData?.apRequestor}
                            currentUser={currentUser?.userID}
                        />

                        {
                            formData?.commentHistoryItems &&
                            <CommentHistory
                                reviewData={formData}
                            />
                        }

                    </div>

                    {
                        openAddComment &&

                        <AddComments
                            openComment={openAddComment}
                            closeComment={() => setOpenAddComment(false)}
                            onSubmit={handleAddComment}
                        />
                    }

                    {
                        (!formDisableCondition || enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)) &&
                        <div className='cc-submit-btn-cnt'>
                            <Button className='ortho-btn' onClick={handleSave}>Save</Button>
                            <Button className='ortho-btn' style={{ marginLeft: "30px" }} onClick={() => submitBtnRef.current.click()}>Submit</Button>

                            {
                                [AR_REQUESTED_INFORMATION, AP_REQUESTED_INFORMATION].includes(formData?.actionType) &&
                                <>
                                    <Button className='ortho-btn' style={{ marginLeft: "30px" }} onClick={handleCancel}>Cancel Request</Button>
                                    <Button className='ortho-btn' style={{ marginLeft: "30px" }} onClick={() => setOpenAddComment(true)} >Add Comments</Button>
                                </>
                            }
                        </div>
                    }




                </div>
            </Spin>
        </div>
    )
}

export default RequestForm;